import * as React from "react";
import styles from "./InvoicePayButton.module.scss";
import IconCube from "public/img/icon-cryptomus-cube.svg";
import { useTranslation } from "next-i18next";
import cn from "classnames";
import { Button } from "components/shared/Button/Button";

interface IInvoicePayButton {
  isDisabled?: boolean;
  isLoading?: boolean;
  handlePay?: () => void;
  darkTheme?: boolean;
}

const InvoicePayButton: React.FC<IInvoicePayButton> = ({
  isDisabled,
  isLoading,
  handlePay,
  darkTheme,
}) => {
  const { t } = useTranslation("common");

  return (
    <Button
      disabled={isDisabled}
      className={cn(styles.invoice__btn, { [styles.invoice__btn_dark]: darkTheme })}
      size="l"
      loading={isLoading}
      fullWidth
      onClick={handlePay}
    >
      <p>
        <IconCube />
        {t("accordion.Pay with Cryptomus")}
      </p>
    </Button>
  );
};

export default InvoicePayButton;
